/* login.module.css */

.login-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 40px 20px 32px 20px;
  border: 1px solid #d5d9e8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.login-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.login-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-container input {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d5d9e8;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.login-container input:focus {
  outline: none;
  border-color: #1976d2;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  margin-top: 14px;
}

.login-button:hover {
  background-color: #1E88E5;
}

.login-container .social-login {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-container .social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #d5d9e8;
  border-radius: 6px;
  font-size: 15px;
  cursor: pointer;
  background-color: #ffffff;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.login-container .social-button img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.login-container .social-button:hover {
  background-color: #f1f1f1;
}

.login-container .terms {
  text-align: center;
  font-size: 12px;
  color: #777;
  margin-bottom: 20px;
  margin-top: 3px;
  width: 80%;
}

.login-container .terms a {
  color: #1976d2;
  text-decoration: none;
}

.login-container .terms a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .login-container {
    width: 90%;
    padding: 30px 15px;
  }

  .login-container h2 {
    font-size: 20px;
  }

  .login-container button {
    font-size: 14px;
  }
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-container input {
  width: 100%;
  padding-right: 40px; /* Adjust based on the size of the button */
}

.toggle-password {
  position: absolute;
  right: 18px;
  top: 15px;
  background: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 0;
}

.signup-title {
  font-weight: 400;
  margin-left: 5px;
}

.signin-prompt {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  color: #1c4ea6; /* Adjusted color */
  font-size: 0.875rem; /* Adjusted size */
  text-decoration: none;
}

.signin-prompt:hover {
  text-decoration: underline;
  cursor: pointer;
}
