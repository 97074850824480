.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  padding: 24px 28px;
  border-radius: 16px;
  width: 420px;
  max-width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.2s ease-out;
}

.modal h2 {
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 12px;
  color: #333;
}

.modal p {
  font-size: 15px;
  margin-bottom: 16px;
  color: #555;
}

.select {
  width: 100%;
  padding: 10px 12px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 20px;
  background: #f9f9f9;
  appearance: none;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.closeButton {
  background: #aaa;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
}

.closeButton:hover {
  background: #888;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-12px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.confirmButton {
  background: #2463eb;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
}

.confirmButton:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.confirmButton:hover:enabled {
  background: #1c4ed8;
}

.link {
  color: #4a90e2;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #2c6acb;
}