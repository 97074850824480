/* 📌 Non-blocking floating panel */
.videoInfoPanel {
  position: fixed;
  top: 60px;
  right: 0;
  width: 360px;
  max-width: 90vw;
  height: calc(100vh - 60px);
  background: white;
  border-left: 3px solid #3498db;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.videoInfoPanel.open {
  transform: translateX(0);
}

/* 🔳 Header */
.infoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.videoTitle {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* ❌ Close button */
.closeButton {
  background: none;
  border: none;
  font-size: 22px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #333;
}

/* 📄 Content */
.infoContent {
  margin-top: 15px;
  font-size: 16px;
  color: #444;
  line-height: 1.6;
}

/* 📝 Description container for handling long text */
.descriptionContainer {
  margin-top: 10px;
}

.videoDescription {
  max-height: 250px;
  overflow-y: auto;
  padding: 5px;
  background: #f9f9f9;
  border-radius: 5px;
  font-size: 14px;
  color: #666;
}

/* 📱 Mobile Optimization */
@media (max-width: 600px) {
  .videoInfoPanel {
    width: 85vw;
    height: auto;
    max-height: 80vh;
    border-left: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 0;
    top: auto;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  .videoInfoPanel.open {
    transform: translateY(0);
  }

  .infoHeader {
    padding-bottom: 15px;
  }
}

/* 📅 Publish Date on the Same Line */
.publishLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
}

/* 📆 Date (Bold & Clear) */
.publishDate {
  color: #333;
}

/* ⏰ Time (Smaller & Lighter) */
.publishTime {
  font-size: 14px;
  color: #777;
}
