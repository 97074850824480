.Layout {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  height: 100dvh;
}

.mastheadContainer {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  background-color: #f6f6f6;
  align-items: center;
  width: 100%;
  min-height: 57px;
}

.content {
  flex-grow: 1;
  background-color: white;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
  overflow-x: hidden;
}

.watch-page-content {
  flex-grow: 1;
  background-color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}

.content-lightsoff {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: RGB(29, 29, 31);
  height: calc(100dvh - 56px);
}
