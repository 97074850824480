.videoCard {
  width: 300px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #d5d9e8;
  position: relative;
}

.videoCard:not(.failed):hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.videoCard.disabled {
  cursor: not-allowed;
  opacity: 0.9;
  background-color: #fff3e0;
}

.highlight {
  border-color: #d9ae23;
}

.videoThumbnail {
  width: 100%;
  height: 166px;
  object-fit: cover;
}

.videoThumbnail.processing {
  filter: grayscale(100%);
  opacity: 0.7;
}

.iconContainer {
  width: 100%;
  height: 180px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.transcriptIcon {
  font-size: 72px;
  color: #4f74b2;
}

.videoInfo {
  padding: 10px;
  background-color: white;
}

.videoTitle {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.videoAuthor {
  font-size: 14px;
  color: #666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progressBarContainer {
  margin-top: 10px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 8px;
}

.progressBar {
  height: 100%;
  background-color: #e3b836;
  transition: width 0.3s ease;
  animation: progress-pulse 2s infinite;
}

@keyframes progress-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 152, 0, 0.8);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 152, 0, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 152, 0, 0.8);
  }
}

.infoButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.videoCard:hover .infoButtonContainer {
  display: block;
}

.infoButton {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
}

.infoButton:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
}

.retryContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 245, 245, 0.9);
  border: 1px solid #f44336;
  border-radius: 5px;
  padding: 7px;
  margin-top: 10px;
}

.retryButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 13px;
  width: 4.2rem;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: bold;
}

.retryButton:hover {
  background-color: #d32f2f;
  transform: translateY(-2px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.failedText {
  color: #f44336;
  font-weight: bold;
  margin-left: 10px;
  font-size: 13px;
  text-transform: uppercase;
}

.progressText {
  font-size: 14px;
  color: #f49208;
  text-transform: capitalize;
  font-weight: bold;
}

.selectCheckbox {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* opacity: 0; */
  transition: opacity 0.2s ease-in-out;
}

/* From Uiverse.io by OptX71285225 */ 
/* Hide the default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox container */
.checkboxContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 2;
  font-size: 18px; /* Make the checkbox slightly bigger */
  margin-bottom: 12px;
}

/* Style the checkmark */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px; /* Make the checkbox slightly bigger */
  width: 30px; /* Make the checkbox slightly bigger */
  border: 2px solid #a3a3a3; /* Default color for the box */
  border-radius: 6px; /* Round the corners */
  background-color: #c8c8c8; /* Default color for the box */
  transition: all 0.3s ease; /* Add smooth transition */
}

/* Show the checkmark when checkbox is checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
  animation: checkboxExpand 0.3s ease forwards;
}

/* Style the checkmark/indicator */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%) rotate(45deg) scale(0); /* Center and hide initially, then scale to appear */
  width: 8px; /* Adjust size */
  height: 15px; /* Adjust size */
  border: solid #fff; /* Change color of the tick to white */
  border-width: 0 3px 3px 0; /* Adjust thickness and direction */
}

/* On hover, add a lighter blue background color */
.checkboxContainer:hover .checkmark {
  background-color: #dbdada;
}

/* When the checkbox is checked, add a black background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #2196f3;
  border-color: #2196f3; /* Change border color */
}

/* When the checkbox is checked, change the tick color to blue */
.checkboxContainer input:checked ~ .checkmark:after {
  border-color: #ffffff; /* Change tick color to blue */
}

/* Keyframes for expanding animation */
@keyframes checkboxExpand {
  0% {
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(45deg) scale(1);
  }
}

