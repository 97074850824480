.chatButtonContainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */ /* Full viewport height for vertical centering */
  width: 100%; /* Full width */
  margin-top: 5px;
  margin-bottom: 10px;
  gap: 10px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 4px;
  height: 40px;
  width: auto;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.lable {
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 0px;
}

.button:hover svg {
  transform: scale(1.25);
  transition: transform 0.2s ease-in-out;
}

.studySpaceEditButton {
  background: #a549da3d;
}

.studySpaceEditButton:hover {
  background: #a549da62;
}

.studySpaceDeleteButton {
  background: #fcccc4;
}

.studySpaceDeleteButton:hover {
  background: #f7bab7;
}

.chatbotButton {
  background: #056bfa27;
}

.chatbotButton:hover {
  background: #056bfa49;
}

.button:disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
  opacity: 0.7;
}

.button:disabled svg {
  opacity: 0.5;
}
