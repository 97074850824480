.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Start slightly above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

.modalContent {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 700px; /* Make it wider to fit more content */
  max-width: 90%;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow */
  animation: slideInFromTop 0.6s ease-out forwards;
}

.modalTitle {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #6196f3; /* Dark blue to match theme */
}

.modalBody {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-bottom: 8px;
}

.modalSectionTitle {
  display: flex;
  justify-content: center;
}

.modalSection {
  width: 48%; /* Left section takes nearly half */
  text-align: center;
  padding: 15px;
  border: 1px solid #d5d9e8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box; /* Include padding in width */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
  height: auto; /* Automatically adjust height to match the right section */
}

.modalSectionRight {
  width: 48%; /* Right section for both Way 2 and Way 3 */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures Way 2 and Way 3 are vertically stacked */
  height: auto;
  row-gap: 15px;
}

.way2And3Section {
  padding: 15px;
  border: 1px solid #d5d9e8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
  flex-grow: 1; /* Ensures Way 2 and Way 3 stretch to take up available space */
}

.modalSectionTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.modalButton {
  padding: 10px 15px;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  margin-top: 14px;
  width: fit-content;
  font-weight: 600;
}

.modalButton:hover {
  cursor: pointer;
}

.modalButton:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
}

.discordButtonWrapper {
  text-align: center;
  margin-top: 20px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.urlInput {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-top: 10px;
  margin-bottom: 8px; /* Add space between input and button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.urlInput:focus {
  border-color: #080535;
  outline: none;
}

.urlInputWay1 {
  width: 100%;
  height: 60%;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-top: 10px;
  margin-bottom: 8px; /* Add space between input and button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
  resize: none;
}

.urlInputWay1:focus {
  border-color: #080535;
  outline: none;
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: 95%;
    max-width: 95%;
    padding: 20px;
    height: 95%;
    overflow-y: auto;
  }

  .modalBody {
    flex-direction: column;
    row-gap: 20px;
    column-gap: 0;
  }

  .modalSection,
  .modalSectionRight {
    width: 100%;
  }

  .modalSection {
    height: auto;
  }

  .way2And3Section {
    flex-grow: 0;
  }

  .urlInputWay1 {
    height: 55px;
  }
}
