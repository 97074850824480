.videoList {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  transition: flex 0.3s ease;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%; /* Takes full viewport height */
}

.submitButton {
  animation: 1.5s ease infinite alternate running shimmer;
  background: linear-gradient(90deg, #ffe27d 0%, #64e3ff 30%, #9192ff 85%);
  background-size: 200% 100%;
  border: none;
  border-radius: 6px;
  box-shadow:
    -2px -2px 10px rgba(255, 227, 126, 0.5),
    2px 2px 10px rgba(144, 148, 255, 0.5);
  color: #ffffff;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 670;
  line-height: 24px;
  overflow: hidden;
  padding: 12px 24px;
  position: relative;
  text-decoration: none;
  transition: 0.2s;
  margin-top: 18px;
}

.submitButton svg {
  left: -20px;
  opacity: 0.5;
  position: absolute;
  top: -2px;
  transition: 0.5s cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.submitButton:hover svg {
  opacity: 0.8;
  transform: translateX(50px) scale(1.5);
}

.submitButton:hover {
  transform: rotate(-3deg);
}

.submitButton:active {
  transform: scale(0.95) rotate(-3deg);
}

@keyframes shimmer {
  to {
    background-size: 100% 100%;
    box-shadow:
      -2px -2px 6px rgba(255, 227, 126, 0.5),
      2px 2px 6px rgba(144, 148, 255, 0.5);
  }
}

.urlInput {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-bottom: 20px; /* Add space between input and button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.urlInput:focus {
  border-color: #080535;
  outline: none;
}

.urlForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Limit the width of the input form */
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0; /* Add some margin to create space around */
}

.inputTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.scrollContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 95px;
}

.scrolldown {
  --color: rgb(115, 115, 115);
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse 500ms ease infinite alternate 250ms;
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.progressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 245, 245, 0.9);
  border: 1px solid #ffb300;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.retryButton:active {
  transform: scale(0.98);
}

.progressBarRetrying {
  height: 100%;
  background-color: #ff9800; /* Orange for retrying */
  transition: width 0.3s ease;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(255, 152, 0, 0.6);
}

.progressOverlay {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10; /* Ensure it overlaps the thumbnail */
}

.studyspaceSection {
  padding: 20px;
  background-color: white; /* Light background to differentiate */
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
}

.studyspaceSection.shrink {
  max-width: calc(100% - 320px); /* Adjust width when the panel is open */
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center the title for balance */
  color: #333; /* Darker color for contrast */
}

.infoIcon {
  margin-left: 8px; /* Space between the title and the icon */
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #888; /* Light color for the icon */
}

.infoIcon i {
  font-size: 18px; /* Adjust icon size */
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px; /* Smaller font size */
  position: absolute;
  z-index: 1;

  /* Position tooltip to the right of the icon */
  top: 50%;
  left: 140%; /* Shift the tooltip to the right of the icon */
  transform: translateY(
    -50%
  ); /* Center the tooltip vertically relative to the icon */

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipText::after {
  content: "";
  position: absolute;
  top: 50%; /* Align the triangle with the center vertically */
  left: -10px; /* Move it just to the left of the tooltip */
  transform: translateY(-50%);
  color: #333;
  /* Create the triangle */
  border-width: 6px;
  border-style: solid;
  border-color: transparent #333 transparent transparent; /* Right-facing arrow */
}

/* Show tooltip on hover */
.infoIcon:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

/* Mobile-specific styles to position the tooltip below the icon */
@media (max-width: 980px) {
  .tooltipText {
    top: 120%; /* Position tooltip below the icon */
    left: 50%; /* Center the tooltip horizontally */
    transform: translateX(-50%); /* Adjust to center */
    max-width: 100px;
  }

  .tooltipText::after {
    top: -10px; /* Move the triangle to the top of the tooltip */
    left: 50%; /* Center the arrow horizontally */
    transform: translateX(-50%); /* Center align the triangle */
    /* Create the upside-down triangle */
    border-color: #333 transparent transparent transparent; /* Downward-facing arrow */
  }
}

.chatButtonContainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */ /* Full viewport height for vertical centering */
  width: 100%; /* Full width */
  margin-top: 5px;
  margin-bottom: 10px;
}

.chatToggleButton {
  padding: 12px 24px;
  background-color: #1a1f71; /* Matching deep blue color for consistency */
  color: white;
  border: none;
  border-radius: 8px; /* Keep rounded corners, but not overly so */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s ease; /* Smooth transition */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.chatToggleButton:hover {
  background-color: #142b8a; /* Slightly darker on hover */
  transform: translateY(-3px); /* Subtle lift effect on hover */
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.chatToggleButton:active {
  transform: translateY(1px); /* Press effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Reduced shadow on press */
}

.chatToggleButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(26, 31, 113, 0.4); /* Focus ring for accessibility */
}

.searchContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.searchBar {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.searchBar:focus {
  border-color: #333;
}

.studyspaceContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.videoListContainer {
  flex: 1;
}

.videoListWithInfo {
  flex: 0.6;
}
